.content-inner .left-panel .left-panel-header .language-header-container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

/* .content-inner .left-panel .left-panel-header .language-header-name[data-customClass="custom-design"].language-header-name[data-customClass="custom-design"] {
    color: var(--heading-text-color);
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    text-transform: uppercase;
} */

.language-form-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dcdbdb;
    padding: 10px 10px 10px 20px;
}

.language-form-header>.language-form-heading {
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
    margin: 0;
}

.language-form-header>.language-form-sub-heading {
    font-size: var(--form-sub-heading-font);
    font-weight: var(--form-sub-heading-weight);
    color: var(--form-sub-heading-color);
    line-height: var(--form-sub-line-height);
}

.language-form-panel {
    padding-left: 20px;
    padding-right: 20px;
}

.language-name {
    margin-top: 10px;
    margin-bottom: -20px;
    display: flex;
    gap: 73px;
}

.language-form-buttons-panel {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke;
    color: var(--heading-text-color);
    text-align: right;
}


.create-language-button[data-customClass="custom-design"]:hover {
    background: var(--accent-color) !important;
    border: none !important;
    color: var(--accent-color-inverted);
}

.language-header {
    border-radius: 0 !important;
    height: 50px;
    text-transform: capitalize;
    margin: 0 !important;
}

.languages-list-block>.language-list-child-block {
    border-bottom: 1px solid #e4e4e4 !important;
    text-transform: capitalize;

}
.language-list-child-block * {
    color: var(--sub-heading-text-color);
    font-size: 12px!important;
}

.language-list-child-block {
    justify-content: space-between;
    align-items: last baseline;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 12px 6px 3px 12px;
    text-transform: capitalize;
    border-bottom: 1px solid #e4e4e4 !important;
}

.language-search-filter-button {
    margin-left: auto;
    border: none;
    border-left: 1px solid #dddddd;
    height: 31px;
    background: transparent;
    padding-right: 0px;
    width: 25px;
}

.language-list-panel {
    display: flex;
    background-color: var(--secondary-color);
    ;
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    justify-content: space-between;
    margin-top: 7px;
    border: 2px solid transparent;
}

.language-list-panel.active {
    border: 2px solid var(--accent-color);
}

.language-list-panel:focus {
    border: 2px solid var(--accent-color);
}

.language-button-container {
    margin-left: auto;
    margin-right: 25px;
}


.language-button-container .langauge-button {
    background: #d5d1d1 !important;
    border-radius: 10px;
    color: var(--heading-text-color) !important;
    font-size: 10px;
    font-weight: 500;
    height: 0;
    padding: 9px;
    text-align: center;
    text-transform: capitalize;

}

.language-inner-panel-container {
    text-transform: capitalize;
    margin-left: 11px;
}

.language-inner-panel-container .language-name-container {
    color: var(--heading-text-color);
    text-transform: capitalize;
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);

}

.middle-panel .language-header-panel {
    width: 100%;
    height: 40px;
}

.middle-panel .language-header-panel .language-header-list-panel {
    gap: 27px;
    display: flex;
    border-radius: 10px;
    align-items: center;
    padding: 15px 15px 15px 20px;
}

.language-header-heading {
    text-transform: capitalize;
    margin-right: auto;
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
}



.middle-panel .language-content-panel {
    width: 100%;
    height: calc(100% - 40px);
    border-radius: 10px;
    padding: 10px 20px 0px 20px;
}


.language-date-time-list {
    color: var(--sub-heading-text-color);
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
}

.langauge-search-button {
    width: 100%;
    margin-top: 20px;
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9E9;
    font-weight: 700;
    color: var(--heading-text-color);
    font-size: 13px;
    border-radius: 8px;
}

.reactSearchbar {
    font-size: 13px;
    margin-top: 20px;
}

.language-list-more-icon {
    transform: rotate(89deg);
    position: absolute;
    right: 10px;
    margin-top: -7px;
    /* color: #767373; */
    cursor: pointer;
}

.language-button-container .langauge-button:hover {
    background: #d5d1d1 !important;
}



.delete-box-button {
    justify-content: flex-end;
    float: left;
    padding: 15px;
    width: 100%;
    background: #F8F8F8;
    display: flex;
}

.delete-box-text {
    text-align: center;
    padding-top: 5px;
    display: flex;
    flex-direction: column;

}

.delete-title-first {
    font-size: 15px;
}

.delete-title-two {
    font-size: 10px;
}

.default-language[data-customClass="custom-design"] {
    text-transform: capitalize !important;
    background-color: #e3dddd !important;
    font-size: 10px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 67px;
}

.languageTablist span:focus,
.languageTablist span[aria-selected="true"] {
    background: transparent!important;
    border: 1px solid var(--accent-color);
    color: var(--heading-text-color);
    font-weight: 600;
}

.languageTablist span {
    border: 1px solid #e6dfdf;
    padding: 0 5px!important;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 96px;
    text-align: center;
    line-height: 25px;

}

.languageTablist-create.formTab span:focus,
.languageTablist-create.formTab span[aria-selected="true"] {
    background: transparent!important;
    border: 1px solid var(--accent-color);
    color: var(--heading-text-color);
    font-weight: 600;
}
.languageTablist-create.formTab span {
    border: 1px solid #e6dfdf;
    display: block;
    overflow: hidden;
    padding: 0 5px!important;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 118px;
    line-height: 25px;
}

.viewTab {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
}

.formTab { 
    display: flex;
    justify-content: space-between;
}

.languageTablist-create.formTab {
    margin: 30px 37px 20px 0;
}