body {
  overflow: auto;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

#root {
  height: 100%;
  margin: 0px;
  display: flex;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

#sidebar-left {
  flex-shrink: 0;
}

.content-box {
  height: 100%;
  display: flex;
  flex-direction: column;
}

ul {
  /* display: flex; */
  list-style: none;
}

.content-wrapper {
  background-color: var(--primary-color);
  border-radius: 20px;
  width: 100%;
  height: calc(100vh - 60px);
}

.item-list-container {
  height: 100%;
  /* background-color: red; */
  border-radius: 10px;
}

/* .content-wrapper >*{
  height: 906px;
  border-radius: 10px;
} */
#content-box {
  width: 100vw;
}

p {
  margin: 0;
}

/* *************************** */

.content-inner {
  display: grid;
  grid-template-columns: 33% auto 22%;
  height: 100% !important;
  padding: 20px 20px 0 20px;
}

.content-inner .left-panel {
  height: calc(100% - 20px);
  padding-right: 20px;
  border-radius: 10px;
}

.content-inner .middle-panel {
  height: calc(100% - 20px);
  background-color: var(--secondary-color);
  border-radius: 10px;
  margin-right: 20px;
  position: relative;
}

.content-inner .right-panel {
  height: calc(100% - 20px);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background-color: transparent;
}

.content-inner .left-panel .left-panel-header {
  width: 100%;
  /* height: 70px; */
  /* background-color: red; */
}

.content-inner .left-panel .left-panel-content {
  width: 100%;
  height: 100%;
  /* background-color: gray; */
}

.content-inner .left-panel .middle-panel-content {
  width: 100%;
  height: 100%;
  /* background-color: rgb(15, 97, 137); */
}

.content-inner .right-panel .right-panel-category {
  width: 100%;
  height: 50%;
  /* background-color:orange ; */
  background-color: var(--secondary-color);
  border-radius: 10px;
}

.content-inner .right-panel .right-panel-audio {
  width: 100%;
  box-sizing: border-box;
  /* background-color: lemonchiffon; */

  height: calc(50% - 0px);
  display: flex;
}

.content-inner .right-panel .right-panel-category .right-panel-category-header {
  width: 100%;
  height: 45px;
  /* background-color: aquamarine; */
}

.content-inner .right-panel .right-panel-category .right-panel-category-content {
  width: 100%;
  height: calc(100% - 45px);
  /* background-color: rgb(255, 127, 165); */
}

/* left-middle-left-form-button*/
.create-episode-language-button[data-customClass="custom-design"],
.create-stories-button[data-customClass="custom-design"],
.create-category-button[data-customClass="custom-design"],
.create-tenant-button[data-customClass="custom-design"],
.create-language-button[data-customClass="custom-design"],
.create-announcement-button[data-customClass="custom-design"] {
  /* background: var(--accent-color) !important;
  border-radius: 40px;
  color: var(--accent-color-inverted);
  text-transform: capitalize;
  align-items: center;
  border: none;
  font-size: var(--create-button);
  font-weight: var(--font-weight);
  padding: 14px 8px 14px 8px; */
  background: var(--accent-color) !important;
  border: none;
  border-radius: 40px;
  color: var(--accent-color-inverted);
  font-size: var(--create-button);
  font-weight: var(--font-weight);
  text-transform: capitalize;
}

/* .ub-pr_16px {
  padding: 10px !important;
} */

.create-episode-language-button[data-customClass="custom-design"],
.create-stories-button[data-customClass="custom-design"],
.create-category-button[data-customClass="custom-design"]:hover {
  border: none !important;
  background-color: var(--accent-color) !important;
  color: var(--accent-color-inverted);
}

.create-user-button[data-customClass="custom-design"]:hover {
  background: var(--accent-color) !important;
  color: var(--accent-color-inverted);
}

.create-announcement-button[data-customClass="custom-design"]:hover {
  background: var(--accent-color) !important;
  border: none !important;
  color: var(--accent-color-inverted);
}

.create-episode-language-button[data-customClass="custom-design"],
.create-stories-button[data-customClass="custom-design"],
.create-category-button[data-customClass="custom-design"]:active {
  background-color: #82a7af;
  color: var(--accent-color-inverted);
}

.tenant-edit-container[data-customClass="custom-design"],
.create-tenant-edit-container[data-customClass="custom-design"],
.series-edit-container[data-customClass="custom-design"],
.episode-edit-container[data-customClass="custom-design"],
.categoty-edit-container[data-customClass="custom-design"],
.language-edit-container[data-customClass="custom-design"],
.create-language-edit-container[data-customClass="custom-design"],
.user-edit-container[data-customClass="custom-design"],
.announcement-edit-container[data-customClass="custom-design"],
.user-edit-container[data-customClass="custom-design"] {
  border-bottom: 1px solid #2a2828;
  background-color: var(--dropdown-bg-color);
}

.tenant-edit-container[data-customClass="custom-design"],
.create-tenant-edit-container[data-customClass="custom-design"],
.series-edit-container[data-customClass="custom-design"],
.episode-edit-container[data-customClass="custom-design"],
.categoty-edit-container[data-customClass="custom-design"],
.language-edit-container[data-customClass="custom-design"],
.create-language-edit-container[data-customClass="custom-design"],
.announcement-edit-container[data-customClass="custom-design"],
.user-edit-container[data-customClass="custom-design"],
.user-edit-container[data-customClass="custom-design"]:hover {
  border-bottom: 1px solid #2a2828 !important;
  background-color: var(--dropdown-bg-color) !important;
}

.tenant-edit-delete-text[data-customClass="custom-design"],
.create-tenant-edit-delete-text[data-customClass="custom-design"],
.series-edit-delete-text[data-customClass="custom-design"],
.episode-edit-text[data-customClass="custom-design"],
.episode-delete-text[data-customClass="custom-design"],
.category-edit-delete-text[data-customClass="custom-design"],
.language-edit-delete-text[data-customClass="custom-design"],
.create-language-edit-delete-text[data-customClass="custom-design"],
.announcement-edit-delete-text[data-customClass="custom-design"] {
  font-size: var(--sub-inner-heading);
  color: var(--dropdown-font-color);
}

/*
search-box start
*/
.css-tj5bde-Svg {
  /* background-image: url("../../frontend/src/images/ios-funnel.svg") !important; */
  /* color: transparent; */
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.css-1s2u09g-control {
  -webkit-box-align: center;
  align-items: center;
  background-color: #f1f1f1 !important;
  border-color: #e5e4e4 !important;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  transition: all 100ms;
  box-sizing: border-box;
}

.css-1s2u09g-control:hover {
  border-color: #e5e4e4 !important;
}

.css-1s2u09g-control:focus-visible {
  border: none !important;
}

/* End*/

/*
form-label-font-size-start
*/
.ub-ln-ht_18px {
  font-size: var(--form-label-font-size);
  font-weight: var(--form-label-weight);
  color: var(--form-label-color);
}

/* End*/

/*
form-label-bottom-margin-start
*/
.ub-mb_8px {
  margin-bottom: 3px;
}

.ub-mb_24px {
  margin-bottom: 5px;
}

/* End*/

/* edit-delete panel css*/

.series-edit-container[data-customClass="custom-design"],
.episode-edit-container[data-customClass="custom-design"],
.categoty-edit-container[data-customClass="custom-design"],
.tenant-edit-container[data-customClass="custom-design"],
.create-language-edit-container[data-customClass="custom-design"],
.language-edit-container[data-customClass="custom-design"],
.user-edit-container[data-customClass="custom-design"],
.announcement-edit-container[data-customClass="custom-design"] {
  /* height: 22px; */
  /* gap: 10px; */
  padding-right: 10px;
  padding-left: 0;
}

/* edit-delete-box start*/
.ub-ml_16px {
  margin-left: 8px;
}

/* end*/

.defaultImageWrapper {
  display: inline-block;
  border-radius: 8px;
  overflow: hidden;
  width: 50px;
  height: 50px;
}

.banner-image,
.series-image,
.poster-image,
.category-image,
.profile-image,
.company-logo {
  width: 100%;
  height: 100%;
}

/* form-save-button-css*/

.save-button[data-customClass="custom-design"] {
  background: var(--accent-color);
  margin-right: 7px;
  align-items: center !important;
  border: 1px solid var(--accent-color);
  border-radius: 40px !important;
  color: var(--accent-color-inverted);
  font-weight: var(--font-weight);
  text-transform: capitalize !important;
}

.save-button[data-customClass="custom-design"]:hover {
  border: 1px solid var(--accent-color) !important;
  background-color: var(--accent-color) !important;
  color: var(--accent-color-inverted) !important;
}

.save-new-button[data-customClass="custom-design"] {
  background: var(--accent-color);
  margin-right: 7px;
  align-items: center !important;
  border: 1px solid var(--accent-color);
  border-radius: 40px !important;
  color: var(--accent-color-inverted);
  font-weight: var(--font-weight);
  text-transform: capitalize !important;
}

.save-new-button[data-customClass="custom-design"]:hover {
  border: 1px solid var(--accent-color) !important;
  background-color: var(--accent-color) !important;
  color: var(--accent-color-inverted) !important;
}

.cancel-button[data-customClass="custom-design"] {
  border: none;
  background-color: var(--accent-color);
  font-size: var(--sub-heading);
  font-weight: var(--sub-heading-font-weight);
  margin-right: 7px;
  color: var(--accent-color-inverted);
  border-radius: 40px;
  box-shadow: none !important;
}

.cancel-button[data-customClass="custom-design"]:hover {
  border: none !important;
  background-color: var(--accent-color) !important;
  color: var(--accent-color-inverted);
}

.simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
  left: 2px;
  right: 2px;
  background-color: #a5a5a5;
  color: blueviolet;
  opacity: 1;
  transition: none;
  height: 100% !important;
}

.simplebar-scrollbar:hover {
  background-color: #a5a5a5;
  opacity: 1;
}

.simplebar-track {
  bottom: 0;
  right: 0px;
  z-index: 1;
}

.simplebar-scrollbar.simplebar-visible:before {
  opacity: 1;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 7px;
  pointer-events: all;
}

/* .series-edit-container-container .ub-pt_8px {
  padding: 0 !important;
}

.tenant-edit-main-container .ub-pt_8px {
  padding: 0 !important;
}

.annoucement-edit-main-container .ub-pt_8px {
  padding: 0 !important;
}



.create-language-main-container .ub-pt_8px {
  padding: 0 !important;
}

.language-edit-main-container .ub-pt_8px {
  padding: 0 !important;
}

.profile-picture .ub-pt_8px {
  padding: 0 !important;

} */

.create-category-form,
.create-series-form,
.ub-ln-ht_18px {
  /* color: var(--form-label-color) !important; */
  font-weight: var(--form-label-weight) !important;
}

/* .create-category-form,
.create-series-form,
.ub-fnt-sze_12px {
  font-size: 10px !important;
} */

/*DropDown list css for all Global */
.ub-pt_8px {
  padding: 0 !important;
}

.global-text-area-padding {
  padding: 12px !important;
}

.sortby select {
  font-size: 11px;
  min-width: 100px;
  padding: 0 8px;
  height: 26px;
  margin: 2px 0;
  border-radius: 20px;
}

.languages-list-block * {
  color: var(--sub-heading-text-color);
  font-size: 12px !important;
}

.rsis-image {
  background-position: center !important;
}

.series-edit-container,
.episode-edit-container,
.tenant-edit-container,
.create-language-edit-container,
.announcement-edit-container:before {
  background-color: transparent !important;
}

.create-episode-language-button[data-customClass="custom-design"],
.create-stories-button[data-customClass="custom-design"],
.create-category-button[data-customClass="custom-design"],
.create-tenant-button[data-customClass="custom-design"],
.create-language-button[data-customClass="custom-design"],
.create-user-button[data-customClass="custom-design"],
.create-announcement-button[data-customClass="custom-design"]:focus {
  box-shadow: none !important;
  background-color: var(--accent-color);
}

.create-episode-language-button[data-customClass="custom-design"],
.create-stories-button[data-customClass="custom-design"],
.create-category-button[data-customClass="custom-design"],
.create-tenant-button[data-customClass="custom-design"],
.create-language-button[data-customClass="custom-design"],
.create-user-button[data-customClass="custom-design"],
.create-announcement-button[data-customClass="custom-design"]:active {
  background-color: var(--accent-color);
  color: var(--accent-color-inverted);
}

.left-sidebar-buttons[data-customClass="custom-design"]::before {
  background-color: transparent !important;
}

.import-user-button-design[data-customClass="custom-design"] {
  background-color: transparent !important;
  border: 2px solid var(--accent-color);
  border-radius: 40px !important;
  color: var(--accent-color);
  font-size: var(--create-button) !important;
  font-weight: var(--font-weight) !important;
  text-transform: capitalize !important;
}

.import-user-button-design[data-customClass="custom-design"]:hover {
  border: 2px solid var(--accent-color) !important;
}

.create-share-button-design[data-customClass="custom-design"] {
  background-color: initial !important;
  border: 2px solid var(--accent-color);
  border-radius: 40px !important;
  color: var(--accent-color);
  font-size: var(--create-button) !important;
  font-weight: var(--font-weight) !important;
  text-transform: capitalize !important;
}

.create-share-button-design[data-customClass="custom-design"]:hover {
  border: 2px solid var(--accent-color) !important;
}

/* sortby css design*/
.ub-b-btm_1px-solid-edeff5 * {
  color: var(--dropdown-font-color) !important;
  font-size: var(--sub-inner-heading) !important;
}

.ub-b-btm_1px-solid-edeff5 {
  border-bottom: 1px solid #2a2828 !important;
  background: black !important;
}

.delete-button[data-customClass="custom-design"] {
  background-color: #d04444;
  border: none;
  border-radius: 40px;
  color: black;
  margin-right: 7px;
  font-weight: 600;
}

.delete-button:hover[data-customClass="custom-design"] {
  background-color: #d04444 !important;
  border: none !important;
  border-radius: 40px;
  color: black;
  margin-right: 7px;
  font-weight: 600;
}

.delete-box-container-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
  padding: 10px 16px 20px 31px;
  border-bottom: 1px solid #dedede;
}

.css-12b25td[disabled],
[data-css-12b25td][disabled] {
  cursor: not-allowed;
  pointer-events: none;
  color: rgb(193, 196, 214);
  border-color: rgb(230, 232, 240);
  background: transparent;
  border: 1px solid;
}

.episode-empty-poster-container,
.series-empty-poster-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.episode-empty-poster-circle,
.series-empty-poster-circle {
  width: 150px;
  height: 150px;
}

.empty-poster,
.series-poster {
  border: 1px solid #e9e9e9;
  border-radius: 50%;
}

/* path#speedometer {
  fill: var(--accent-color-inverted);
} */

.css-26l3qy-menu {
  border: 1px solid #d5d5d5 !important;
  box-shadow: none !important;
  font-size: 12px !important;
}

.css-1n7v3ny-option {
  border-bottom: 1px solid #e6e6e6 !important;
}

.tenant-form-panel {
  background-color: transparent !important;
}

/* create tenant form bg color*/
.ub-bg_nrwqn2 {
  background: transparent !important;
}

/* account detail info font color*/
.ub-color_a8a4a4 {
  color: #343434 !important;
}

.import-user-button-design:active {
  background-color: transparent !important;
}

.create-user-button[data-customClass="custom-design"] {
  font-size: 12px;
  font-weight: 700;
  text-transform: capitalize;
}

span.text-two:first-letter {
  text-transform: capitalize !important;
}

span.text-one:first-letter {
  text-transform: capitalize !important;
}

span.text-one:first-letter {
  text-transform: capitalize !important;
}

p.category-item-list-text-name:first-letter {
  text-transform: capitalize !important;
}

/* scroll bar css*/
.podcast-content-wrapper .simplebar-track {
  right: -10px;
}

.left-panel-content .simplebar-track {
  right: -10px;
}

.episode-list-data .simplebar-track {
  right: -10px;
}

.right-panel-category-content .simplebar-track {
  right: 4px;
}

.episode-list-data .simplebar-track {
  right: -10px;
}

.category-item-list-text-name:first-letter {
  right: -10px;
}

.carousel-root {
  height: 100%;
}

.carousel-slider {
  height: 100%;
}

.slider-wrapper.axis-horizontal {
  height: 100%;
}

ul.slider.animated {
  height: 100%;
}

.carousel .slide img {
  border: 0;
  border-radius: 8px !important;
  /* height: 212px!important; */
  vertical-align: top;
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100% !important;
  object-fit: cover;
}

ul.slider.animated {
  height: 100%;
}

.announcement-content-panel .simplebar-track {
  right: -10px;
}

.announcement-content-panel .simplebar-track.simplebar-horizontal {
  height: 0;
}

.sort-filter {
  padding: 10px 20px 10px 20px !important;
}

.noDataFound {
  font-size: 12px;
}

/* 
Global box shadow 
*/
.css-lfowci:focus, [data-css-lfowci]:focus{
  box-shadow: none !important;
}

/* 
Global background color 
*/
.css-lfowci:hover, [data-css-lfowci]:hover{
  background-color: transparent !important;
}
.rhap_progress-bar{
  height: 4px !important;
}