.create-series-form[data-customClass="custom-design"] {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
}

.series-main-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
}

.first-line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.select-all-series-checkbox {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: -3px;
}

.selectAllSeries {
    margin-bottom: 13px;
}

.edit-episode-form {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dcdbdb;
    padding: 10px 10px 10px 20px;
}

.edit-episode-form>.edit-episode-form-heading {
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
}

.edit-episode-form>.edit-episode-sub-heading {
    font-size: var(--form-sub-heading-font);
    font-weight: var(--form-sub-heading-weight);
    color: var(--form-sub-heading-color);
    line-height: var(--form-sub-line-height);
}

.edit-category-form {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dcdbdb;
    padding: 10px 10px 10px 20px;
}

.edit-category-form>.edit-category-form-heading {
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
}

.edit-category-form>.edit-category-sub-heading {
    font-size: var(--form-sub-heading-font);
    font-weight: var(--form-sub-heading-weight);
    color: var(--form-sub-heading-color);
    line-height: var(--form-sub-line-height);
}

.library-form-header,
.edit-series-form-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dcdbdb;
    padding: 10px 10px 10px 20px;
}

.edit-series-form-header>.edit-series-form-heading {
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;

}

.edit-series-form-header>.edit-series-form-sub-heading {
    font-size: var(--form-sub-heading-font);
    font-weight: var(--form-sub-heading-weight);
    color: var(--form-sub-heading-color);
    line-height: var(--form-sub-line-height);
}


.library-form-header>.library-form-heading {
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
}

.library-form-header>.library-form-sub-heading {
    font-size: var(--form-sub-heading-font);
    font-weight: var(--form-sub-heading-weight);
    /* color: var(--form-sub-heading-color); */
    line-height: var(--form-sub-line-height);
}


.create-series-search-button {
    width: 100%;
    margin-top: 20px;
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9E9;
    color: var(--heading-text-color);
    border-radius: 8px;
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
}

.create-series-searchicon-two {
    margin-left: -7px;
    margin-right: 12px;
}

.second-line {
    display: flex;
    justify-content: space-between;
    background: var(--secondary-color);
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    margin-bottom: 6px;
    margin-top: 8px;
    border: 2px solid transparent;
}


.second-line.active, .second-line:focus {
    border: 2px solid var(--accent-color);
}



.podcast-series-items-list-panel-one[data-customClass="custom-design"] {
    display: flex;
    gap: 12px !important;
    align-items: center;
}

.library-item-image {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 10px;
    margin-left: -6px;
}


.podcast-text-name {
    display: flex;
    flex-direction: column;
}

.podcast-text-name>.text-one[data-customClass="custom-design"] {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: var(--sub-heading-text-color);
    /* text-transform: capitalize; */
    line-height: var(--line-height);
}

.podcast-text-name>.text-two {
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    color: var(--heading-text-color);
    /* text-transform: capitalize; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
    white-space: nowrap;
}

.library-item-image .library-img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.podcast-series-list-date-time[data-customClass="custom-design"] {
    display: flex;
    gap: 11px;
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: rgb(48, 48, 48);
   
    
}

.inner-wrapper[data-customClass="custom-design"] {
    display: flex;
    align-items: center;
    gap: 5px;
}

.calender-img {
    width: 10px;
}

.moreicon {
    transform: rotate(89deg);
    position: relative;
    top: -19px;
    color: #b5b0b0;
    left: 18px;
}

.ub-pr_16px {
    /* padding-right: 8px; */
}

.prodcast-series-icon.ub-box-szg_border-box {
    /* 
.prodcast-series-icon {
    float: right;
    display: flex;
    gap: 14px;
    margin-top: 8px;
    color: #c8c4c4; */
}

.select-podcast {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: #b5a8a8;
}

.select-podcast-title {
    margin-left: 4px;
}


.second-box {
    background-color: var(--secondary-color);
    /* padding: 20px; */
}

button.Episodes,
.Series-Info {
    border: none;
    margin-left: -3px;
    background: transparent;
    margin-right: -15px;
    font-size: 12px;
    font-weight: 700;
    color: var(--sub-heading-text-color);
}


.second-box-search {
    width: 70%;
    border-radius: 15px;
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: var(--heading-text-color);
    height: 27px;
    border: 1px solid #e5e5e5;
}

.second-box-container {
    display: flex;
    align-items: center;
    margin-right: auto;
    gap: 10px;
    margin-left: -4px;
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
}

.episode-list-main-container {
    display: flex;
    justify-content: space-between;
    background: #F8F8F8 0% 0% no-repeat padding-box;
    border-radius: 10px;
    padding: 10px;
    margin-top: 6px;
}

.pane-two {
    display: flex;
    gap: 10px;
    align-items: center;
}

.pane-one {
    display: flex;
    gap: 10px;
    align-items: center;
}

.episode-list-more-icon {
    transform: rotate(89deg);
    cursor: pointer;
    color: black;
}

.eposide-img {
    border-radius: 10px;
    display: block;
    height: 40px;
    max-height: 100px;
    max-width: 100px;
    object-fit: cover;
    opacity: 1;
    width: 40px;

}

.episode-list-text-two {
    color: var(--sub-heading-text-color);
    font-size: var(--episode-sub-title);
    font-weight: var(--episode-sub-title-weight);
}

.episode-list-text-one {
    color: var(--heading-text-color);
    font-size: var(--episode-title);
    font-weight: var(--episode-title-weight);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 140px;
    text-transform: capitalize;
}

.podcast-series-title {
    color: var(--heading-text-color);
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 900;
}

.second-box-Title {
    color: var(--heading-text-color);
    font-size: 16px;
    font-weight: 900;
}

.second-box-title-moreicon {
    display: flex;
    justify-content: space-between;
    padding: 20px 27px 0px 20px
}

.second-box-moreicon {
    transform: rotate(89deg);
    color: black;
    cursor: pointer;
}

.serie-info-image {
    display: block;
    max-width: 100px;
    max-height: 100px;
    width: 75px;
    height: 75px;
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 10px;
}

.serie-info-para {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    text-transform: capitalize;
    color: #606060;

}

.serie-info-buttons {
    margin-left: -6px;
}

button.serie-info-buttons-a {
    border: none;
    color: var(--heading-text-color);
    background: #EBEBEB;
    border-radius: 21px;
    width: 52px;
    height: 22px;
    text-align: center;
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    text-transform: capitalize;
    margin: 5px;
}

button.serie-info-buttons-b {
    border: none;
    color: var(--heading-text-color);
    background: #EBEBEB;
    border-radius: 21px;
    width: 78px;
    height: 22px;
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    text-transform: capitalize;

}

button.serie-info-buttons-a,
button.serie-info-buttons-b:hover {
    border: none !important;
    background: #EBEBEB !important;
}

.serie-info-list-one {
    display: grid;
    margin-top: 10px;
    grid-template-columns: auto 40% 26%;
    text-align: initial;

}

.serie-info-list-text-one[data-customClass="custom-design"] {
    color: var(--sub-heading-text-color);
    font-size: var(--sub-inner-heading);
    font-weight: 600;
    text-transform: capitalize;
}

.serie-info-list-text-two {
    color: var(--heading-text-color);
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    text-transform: capitalize;
}

.serie-info-list-text-b-one-two {
    margin-left: -89px;
}


.create-category {
    background: var(--accent-color);
    border: none;
    padding: 5.5px 9.62px 6.5px 12.38px;
    border-radius: 40px;
    font-size: 11px;
    font-weight: bolder;
}

.category {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 10px 10px;
    height: 46px;
    border-bottom: 1px solid #e9e9e9;
}

.category-bottom-line {
    border-bottom: 1px solid #e9e9e9;
    margin-top: 11px;
}

.category-item-list {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e9e9e9;
    padding: 10px 10px 5px 10px;
}

.category-item-list-text>.category-item-list-text-name {
    font-size: var(--catrgory-title);
    font-weight: var(--category-title-font-weight);
    color: var(--heading-text-color);
    /* text-transform: inherit; */
    overflow: hidden;
    text-overflow: ellipsis;
    width: 125px;
    white-space: nowrap;
    /* text-transform: capitalize; */

}

.category-item-list-text>.category-item-list-date {
    font-size: var(--category-sub-title);
    font-weight: var(--category-sub-title-font-weight);
    text-transform: capitalize;
    color: var(--sub-heading-text-color);
}

.category-item-list-date {
    font-size: 9px;
    color: var(--sub-heading-text-color);
    text-transform: uppercase;
    font-weight: bold;
}

.category-list-icon {
    transform: rotate(89deg);
}

a:-webkit-any-link {
    color: var(--sub-heading-text-color);
    font: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    cursor: pointer;
    text-decoration: none;
}

.create-episode-language-button[data-customClass="custom-design"] {
    min-width: auto;
}



/* input[type="text"] {
    width: 220px;
    height: 28px;
    margin-top: 6px;
    border-radius: 6px;
    border: 1px solid #c1c4d6;
} */

.Series-description {
    margin-top: 6px;
}

.category-item-list-img-header {
    display: flex;
    gap: 10px;
}

.create-series-filter-icon-button {
    margin-left: auto;
    border: none;
    border-left: 1px solid #dddddd;
    height: 31px;
    background: transparent;
    padding-right: 0px;
    width: 25px;

}

.episode-series-info-box {
    border-bottom: 1px solid #e9e9e9;
    width: 100%;
    padding-left: 20px;
    margin-top: 3px;
}

.category-list-item-container {
    padding: 0 15px 0 15px;
    width: 100%;
}

.pane-two-time {
    color: var(--heading-text-color);
    font-weight: 900;
    font-size: 10px;
}

/* input[type="text"] {
    width: 324px;
    height: 28px;
    margin-top: 6px;
    border-radius: 6px;
    border: 1px solid #c1c4d6;
} */

.create-series-file-uploader,
.create-episode-file-uploader,
.create-episode-image-uploader,
.create-category-image-uploader {
    /* padding: 8px; */
}

.library-form-buttons-panel,
.episode-form-buttons-panel,
.category-form-buttons-panel,
.edit-episode-form-buttons-panel,
.category-edit-buton-panel,
.edit-series-form-panel {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke;
    color: var(--heading-text-color);
    text-align: end;
}

.audio-player-heading {
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    margin: 0;
    color: white;
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 200px;
    white-space: nowrap;
    word-break: break-all;
}

.audio-player-para {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: #d7d5d5;
    text-transform: capitalize;
}

.audio-player-img-para {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: #d7d5d5;
    text-transform: capitalize;
    margin-top: 2px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
}

.audio-player-img {
    border-radius: 8px;
    height: 70px;
    width: 70px;
    margin-top: 7px;
    object-fit: cover;
}

.length input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 10px;
    background: #ffffff;
    cursor: pointer;
}

input[type='range'] {
    height: 4px;
    width: 169px;
    background: rgb(156 211 199);
    -webkit-appearance: none;
    border: none;
    outline: 0;
    border-radius: 5px;
}

.length {
    color: #dcdcdc;
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    margin-left: 10px;
    margin-right: 11px;
}

.audio-player {
    text-align: center;
    text-transform: uppercase;
    border-radius: 10px;
    /* position: absolute;
   right: 29px;
   top: 286px;
   width: 243px; */
    background-origin: border-box;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    /* height: calc(100% - 50%) !important; */
    width: 100%;
    padding: 4px 20px 0 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.first-box-title {
    display: grid;
}

.audio-player-icon {
    padding: 12px 10px 12px 14px;
    background: #b2dcd3;
    box-sizing: content-box;
    border-radius: 28px;
}

div#content-box {
    width: calc(100vw - 200px);
    height: 100vh;
    overflow: hidden;
}

/* .podcast-content-wrapper{
   
   width: 100%;
   height: calc(100% - 108px);
} */
/* .podcast-header-wrapper{
   width: 100%;
   height: 108px;
} */
.second-search-create-container {
    display: flex;
    margin-top: 15px;
    justify-content: space-between;
    padding-left: 20px;
    padding-right: 20px;
}

.episode-list-data {
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 10px;
    height: calc(100% - 78px);
}


.create-episode-header-wrapper {
    width: 100%;
    height: 78px;
}

.rhap_container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    line-height: 1;
    font-family: inherit;
    width: 100%;
    padding: 3px 15px;
    background-color: transparent !important;
    margin-top: -20px !important;
}

.rhap_stacked .rhap_controls-section {
    margin-top: 0 !important;
}

.rhap_repeat-button {
    display: none !important;
}

.rhap_volume-button {
    display: none !important;
}

.rhap_volume-bar-area {
    display: none !important;
}

.rhap_main-controls-button {
    color: var(--accent-color);
}

.rhap_progress-container {
    display: flex;
    align-items: center;
    height: 20px;
    flex: 1 0 auto;
    align-self: center;
    margin: 0 calc(-11px + 1%);
    cursor: pointer;

}

/* .rhap_time {
    color: #908e8e;
    font-size: 10px !important;
    user-select: none;
    position: relative;
    right: 3px;
    top: 15px;
} */

.simplebar-scrollbar::before {
    background-color: rgb(0, 0, 0);
    height: 50px;
}





.create-episode-form-title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 24px;

}

.create-episode-form[data-customClass="custom-design"],
.create-category-form[data-customClass="custom-design"] {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
}

.category-heading-name[data-customClass="custom-design"],
.episode-header-name[data-customClass="custom-design"] {
    color: var(--heading-text-color) !important;
    font-size: var(--heading-title-font-size) !important;
    font-weight: var(--heading-font-weight) !important;
    text-transform: uppercase !important;
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.podcast-series-header-name[data-customClass="custom-design"] {
    color: var(--heading-text-color) !important;
    font-size: var(--heading-title-font-size) !important;
    font-weight: var(--heading-font-weight) !important;
    text-transform: uppercase !important;
}

.edit-series-panel-one {
    display: flex;
    gap: 9px;
}

.podTablist [aria-selected="true"],
.podTablist:focus {
    background: transparent !important;
    color: var(--heading-text-color);
    font-size: 10px;
    border-bottom: 1.5px solid var(--accent-color);
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    box-shadow: unset;
}

#tab1,
#tab2 {
    background: transparent !important;
    color: rgb(139, 139, 139);
    font-size: var(--sub-inner-heading);
    margin-right: 13px;
    padding-left: 0;
    box-shadow: none !important;
}

.series-filter-icon {
    margin-top: 10px;
    margin-left: 10px;
}

/*
  series-edit-panel
  category-edit-panel
  episode-edit-panel
  */

.ub-bg_nrwqn2 {
    background: unset;
}





.searchPod[data-customClass="custom-design"] {
    /* border-radius: 25px; */
    font-size: 11px;
    /* padding: 4px 10px;
    height: auto; */
    width: 100% !important;
}

.searchPod[data-customClass="custom-design"]:focus {
    border: 1px solid #e7e6e6 !important;
    box-shadow: none !important;
}

img.audio-empty-poster {
    max-width: 66px;
    /* border-top-left-radius: 50% 50%;
    border-top-right-radius: 50% 50%;
    border-bottom-right-radius: 50% 50%;
    border-bottom-left-radius: 50% 50%;
    border: 1px solid #454343; */

}

.podcast-player-title[data-customClass="custom-design"] {
    font-size: 10px;
    color: #7a6e6e;
    text-transform: capitalize;
    font-weight: 600;
}

.search-panel {
    display: flex;
    justify-content: space-between;
    gap: 7px;
    align-items: center;
    margin-top: 7px;
    margin-bottom: 7px;
}

.podTablist:hover {
    background: transparent !important;
    border: transparent !important;
    border-bottom: 1px solid #e9e9e9 !important;
}

button.rhap_button-clear.rhap_main-controls-button.rhap_skip-button {
    font-size: 30px;
    margin-top: 5px;
}

.rhap_progress-section {
    display: block !important;
}

.rhap_progress-indicator {
    box-sizing: border-box;
    position: absolute;
    z-index: 3;
    width: 8px;
    height: 8px;
    margin-left: -3px;
    top: -2px;
    background: #53a1b1;
    border-radius: 50px;
    box-shadow: rgb(134 134 134 / 50%) 0 0 5px;
}

div#rhap_current-time {
    font-size: 10px !important;
    color: white;
    position: relative;
    top: 28px;
    right: 62px;
}

.rhap_time.rhap_total-time {
    color: white;
    font-size: 10px !important;
    position: relative;
    left: 68px;
}

.rhap_progress-filled {
    height: 100%;
    position: absolute;
    z-index: 2;
    background-color: #4298a9d4;
    border-radius: 2px;
}

.rhap_download-progress {
    height: 100%;
    position: absolute;
    z-index: 1;
    background-color: var(--accent-color);
    border-radius: 2px;
}

.rhap_progress-bar-show-download {
    background-color: var(--accent-color);
}



.podcast-header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-bottom: 1px solid #f0ebeb;
}

.button.panel {
    padding: 20px;
    display: flex;
    gap: 10px;
    justify-content: flex-end;
}

.cancel-btn {
    background: transparent;
    border: 1px solid #dedede;
    padding: 10px 25px 10px 25px;
    border-radius: 8px;
    cursor: pointer;
}

.share-btn {

    background: var(--accent-color);
    border: none;
    padding: 10px 25px 10px 25px;
    border-radius: 8px;
    color: var(--heading-text-color);
    cursor: pointer;
}

.cancel-btn:focus-visible {
    outline: transparent !important
}

.top-header {
    text-align: center;
}

.switch-title {
    display: flex;
    gap: 10px;
    align-content: center;
    align-items: center;
}

.share-body {
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.switch-button:focus-visible {
    background: transparent !important;
}

.TagInput-text {
    margin-bottom: -18px;
    font-size: 10px;
    margin-top: 25px;
}


.share-icon-wrapper {
    border-radius: 50%;
    width: 23px;
    height: 23px;
    margin-left: auto;
    margin-right: 5px;
    cursor: pointer;
    position: relative;
    top: 2px;
    border: 1px solid #d7d6d6;
}

img.share-icon {
    width: 12px;
    height: 12px;
    margin-left: 4.5px;
}

.share-icon-wrapper:hover {
    background: var(--accent-color);
}

.share-icon {
    position: absolute;
    right: 10px;
    text-transform: capitalize !important;
    font-size: 9px !important;
}

.share-icon-category {
    position: relative;
    float: right;
    right: -20px;
    text-transform: capitalize !important;
    font-size: 9px !important;
}

.share-create-series-button-design {
    display: flex;
    gap: 5px;
}

.text-line {
    overflow: hidden;
    width: 120px;
    text-overflow: ellipsis;
    white-space: nowrap;
}