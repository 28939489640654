:root {
    --primary-color: #F8F8F8 !important;
    --secondary-color: white !important;



    /*color branding colors*/
    --accent-color: #A6CED6;
    --accent-color-inverted: #000000;

    /*font text color for main and sub*/
    --heading-text-color: #000000;
    --sub-heading-text-color: #343434;
    --heading-font-weight: 600;

    /* dropdown list background color*/
    --dropdown-bg-color:#000000;
    --dropdown-font-color:white;
}