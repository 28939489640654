.content-inner .left-panel .left-panel-header .user-header-container {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.user-form-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdbdb;
  padding: 10px 10px 10px 20px;
}

.user-form-heading {
  display: flex;
  justify-content: space-between;
  margin-top: 7px;
  margin-bottom: 5px;
  margin-bottom: -5px;
}

.user-form-heading-title[data-customClass="custom-design"] {
  color: var(--heading-text-color);
  font-size: var(--heading-title-font-size);
  font-weight: var(--font-weight);
  text-transform: capitalize;

}

.user-form-sub-heading[data-customClass="custom-design"] {
  color: var(--form-sub-heading-color);
  font-size: var(--form-sub-heading-font);
  font-weight: var(--form-sub-heading-weight);
  line-height: var(--form-sub-line-height);
}

.user-form-panel[data-customClass="custom-design"] {
  padding-left: 20px;
  padding-right: 20px;
}

.user-detail-form {
  display: flex;
  flex-direction: column;
  margin-top: 7px;
}

.user-form-buttons-panel {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: whitesmoke;
  color: var(--heading-text-color);
  text-align: right;
}

.user-list-panel {
  display: flex;
  background: #fff;
  margin-top: 7px;
  border-radius: 10px;
  padding: 10px;
  align-items: center;
  border: 2px solid transparent;
}

.user-list-panel.active {
  border: 2px solid var(--accent-color);
}

.user-list-panel:focus {
  border: 2px solid var(--accent-color);
}

.user-list-panel .user-img {
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 10px;
}

/* .user-name-date-time-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
} */

.user-name-list {
  font-size: var(--sub-heading);
  font-weight: var(--sub-heading-font-weight);
  color: var(--heading-text-color);
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.left-panel-content .user-list-panel .user-list-img .user-img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.user-inner-panel-container {
  text-transform: capitalize;
  margin-left: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-inner-panel-container .user-email {
  font-size: var(--sub-inner-heading);
  font-weight: var(--sub-inner-heading-font-weight);
  color: var(--sub-heading-text-color);
  text-transform: lowercase;
  line-height: var(--line-height);
}

.left-panel-content .left-panel-content-moreicon {
  transform: rotate(89deg);
  position: relative;
  color: #b5b0b0;
  left: 270px;
  bottom: 77px;
}

.middle-panel .user-header-panel {
  width: 100%;
  height: 80px;
}

.middle-panel .user-content-panel {
  width: 100%;
  height: calc(100% - 80px);
  border-radius: 10px;
}

.middle-info-inner-wrapper {
  padding: 20px;
}

.user-header-list-panel {
  gap: 27px;
  display: flex;
  border-radius: 10px;
  align-items: center;
}

.user-header-img {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 10px;
}

.user-header-panle-img {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
}

.user-header-heading {
  text-transform: capitalize;
  margin-right: auto;
  margin-left: -10px;
  overflow: hidden;
  width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user-header-heading .user-header-name {
  color: var(--heading-text-color) !important;
  font-size: var(--heading-title-font-size) !important;
  font-weight: var(--heading-font-weight) !important;
  text-transform: uppercase !important;
}

.middle-panel-header-moreicon {
  transform: rotate(89deg);
  margin-right: 10px;
  cursor: pointer;
}

.user-content-description-list {
  display: grid;
  grid-template-columns: auto 25% 44%;
  text-align: initial;
}

.user-content-description-list-one {
  color: var(--sub-heading-text-color);
  font-size: var(--sub-inner-heading);
  font-weight: 600;
  text-transform: capitalize;
}

.user-content-description-list-two {
  color: var(--heading-text-color);
  font-size: var(--sub-inner-heading);
  font-weight: var(--sub-inner-heading-font-weight);
  text-transform: lowercase;
}

.user-content-count-list {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 13px;
  margin-top: 30px;
}

.user-count {
  display: flex;
  gap: 10px;
  border: 1px solid #eae7e7;
  width: 100%;
  border-radius: 8px;
  height: 60px;
}

.user-podcast-count {
  display: flex;
  gap: 10px;
  border: 1px solid #eae7e7;
  width: 100%;
  border-radius: 8px;
  height: 60px;
}

.user-list-more-icon {
  transform: rotate(89deg);
  position: absolute;
  right: 10px;
  margin-top: -7px;
}

.ub-ovflw-y_auto {
  overflow: hidden;
}

.create-user-button[data-customClass="custom-design"] {
  background: var(--accent-color);
  padding: 4.5px 15.62px 4.5px 15.38px;
  border-radius: 40px;
  font-size: 11px;
  color: var(--heading-text-color);
  text-transform: capitalize;
  font-weight: 700;
  align-items: center;
  border: none;
}

.create-user-button[data-customClass="custom-design"]:hover {
  background: var(--accent-color) !important;
  border: none !important;
}

.create-user-button[data-customClass="custom-design"]:hover:active {
  background: var(--accent-color) !important;
  border: none !important;
}

.user-search-filter-button {
  margin-left: auto;
  border: none;
  border-left: 1px solid #dddddd;
  height: 31px;
  background: transparent;
  padding-right: 0px;
  width: 25px;
}

.user-icon-one {
  margin: 15px 0px 10px 15px;
}

.user-content-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.badge-inner {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  margin-right: 5px;
}

.badge1 {
  min-width: 100%;
  min-height: 100%;
  object-fit: cover;
  filter: grayscale(1) saturate(1) opacity(0.2);
}

.user-badge-container {
  border: 1px solid #eae7e7;
  border-radius: 8px;
  margin-top: 10px;
}

.user-badge-list-panel {
  padding: 15px 20px 20px 20px;
}

.badge-title {
  color: #343434;
  font-size: 10px;
  font-weight: 500;
  margin-left: 20px;
  margin-top: 15px;
  text-transform: capitalize;
}

.user-add-remove-delete-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 8px;
  color: #b9b6b6;
  margin-right: 5px;
}

.user-header-title[data-customClass="custom-design"] {
  color: var(--heading-text-color) !important;
  font-size: var(--heading-title-font-size) !important;
  font-weight: var(--heading-font-weight) !important;
  text-transform: uppercase !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100px;
}

img.user-desc-list-icon {
  width: 15px;
  height: auto;
}

.edit-user-form-header {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdbdb;
  padding: 10px 10px 10px 20px;
}

.edit-user-form-header>.edit-user-form-heading[data-customClass="custom-design"] {
  font-size: var(--heading-title-font-size);
  font-weight: var(--font-weight);
  color: var(--heading-text-color);
  text-transform: capitalize;
}

.edit-user-form-header>.edit-user-form-sub-heading[data-customClass="custom-design"] {
  font-size: var(--form-sub-heading-font);
  font-weight: var(--form-sub-heading-weight);
  color: var(--form-sub-heading-color);
  line-height: var(--form-sub-line-height);
}

.delete-box-button {
  justify-content: flex-end;
  float: left;
  padding: 15px;
  width: 100%;
  background: #f8f8f8;
  display: flex;
}

.delete-box-text {
  text-align: center;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
}

.delete-title-first {
  font-size: 15px;
}

.delete-title-two {
  font-size: 10px;
}

.user-button-create-import {
  margin-left: auto !important;
  display: flex;
  gap: 5px;
}

img.active-badge-info {
  width: 130px;
  display: block;
  margin: 0 auto;
  margin-top: 15px;
  margin-bottom: 15px;
}

img.badge-info {
  width: 130px;
  display: block;
  margin: 0 auto;
  filter: grayscale(1) saturate(1) opacity(0.2);
  margin-top: 15px;
  margin-bottom: 15px;
}




.badge-header {
  color: var(--heading-text-color) !important;
  font-size: var(--heading-title-font-size) !important;
  font-weight: var(--heading-font-weight) !important;
  text-transform: uppercase !important;
  border-bottom: 1px solid #eae7e7;
  text-align: center;

  padding-bottom: 10px;
}

.badge-name {
  font-size: var(--sub-heading);
  font-weight: var(--sub-heading-font-weight);
  color: var(--heading-text-color);
  margin-top: 8px;
  text-align: center;
}

.badge-desc {
  font-size: var(--sub-inner-heading);
  font-weight: var(--sub-inner-heading-font-weight);
  color: var(--sub-heading-text-color);
  line-height: var(--line-height);
  text-align: center;
  margin-top: 12px;
}

.btn-select-tenant {
  border-bottom: 1px solid #dfdcdc;
  padding-bottom: 8px;
}

p.badge-content {
  color: black;
  font-size: 12px;
  text-align: center;
}

.badge-info-container.ub-box-szg_border-box {
  padding: 20px;
}

.series-text {
  width: 190px;
  word-wrap: break-word;
  text-align: center;
}

.delete-header-text-inner-wrapper {
  word-break: break-all;
}

.role-admin-icon {
  text-transform: capitalize !important;
  font-size: 9px !important;
  position: absolute;
  right: 10px;
}