.flex-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 1px);
    width: 100%;
    overflow: hidden;
}

.input-field {
    width: 100% !important;
    border: 0px !important;
    border: 1px solid rgba(194, 196, 199, 0.8) !important;
}

.login-logo-wrapper{
    display: flex;
    justify-content: center;
}
img.login-logo-img {
    height: auto;
    width: 120px;
}


.Login-text {
    color: #474747;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 800;
}

.login-sub-text {
    font-size: 12px;
    color: #939090;
}

.text-on-image.ub-box-szg_border-box {
    position: absolute;
    top: 45%;
    color: #919191;
    right: 0;
    left: 50%;
    bottom: 0;

}



.flex-item {
    display: flex;
}

.login-wrapper.ub-box-szg_border-box {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
}

.login-wrapper-inner {
    width: 400px;
}

.email-edit,
.password {
    color: #acacbe !important;
    font-size: 12px !important;
    text-transform: capitalize;
}



.login-img-right-side {
    background-image: url("../../images/login_page_img.png");
    background-position: top right;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
}

.login-form {
    margin-top: 20px;
    position: relative;
}

.Login-form-button {
    width: 100% !important;
    margin-top: 20px;
    background-color: #a6ced6 !important;
}

.Login-form-button:hover {
    background-color: #a6ced6 !important;
    border: none !important;
    color: black !important;
}

.login-img-right-side {
    background-image: url("../../images/login_page_img.png");
    background-size: cover;
}

.img-container {
    width: 100%;
    height: 100%;
}

.login-wrapper-inner-body {
    width: 300px;

}

.show-hide{
    cursor: pointer;
    float: right;
    left: auto;
    position: relative;
    right: 11px;
    top: -48px;
    color: #83a7ae;
    width: 14px;
}

.ms-clear::-ms-reveal,
.ms-clear::-ms-clear {
  display: none;
}

.show-hide-one{
    position: absolute;
    top: 34px;
    right: 10px;
    color: #83a7ae;
    cursor: pointer;
    width: 14px;
}
.show-hide-two{
    position: absolute;
    top: 116px;
    right: 10px;
    color: #83a7ae;
    cursor: pointer;
    width: 14px;
}
.show-hide-three{
    position: absolute;
    bottom: 111px;
    right: 10px;
    color: #83a7ae;
    cursor: pointer;
    width: 14px;
}