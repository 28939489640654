.language-container[data-customClass="custom-design"] {
    border-radius: 10px;
    padding: 20px;
    background: white;
}

.language-header-name[data-customClass="custom-design"] {
    font-size: 14px;
    font-weight: 600;
}

.account-setting-details[data-customClass="custom-design"] {
    display: flex;
    justify-content: space-between;
}

.account-setting-details * {
    margin-top: 16px;
}

img.account-profile-img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}