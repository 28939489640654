:root {
  /* Library podcast series Font*/
  --heading-title-font-size: 14px;
  --create-button: 12px;
  --search-text: 10px;
  --sub-heading: 12px;
  --sub-inner-heading: 12px;

  

  /* Library podcast series Font-weight*/
  --font-weight: 600;
  --sub-heading-font-weight: 600;
  --sub-inner-heading-font-weight: 500;
  --line-height: 14px;


  /* Library episode Font*/
  --episode-title: 10px;
  --episode-sub-title: 8px;

  /* Library episode font-weight*/
  --episode-title-weight: 600;
  --episode-sub-title-weight: 500;

  /* Library category font*/
  --catrgory-title: 12px;
  --category-sub-title: 10px;


  /* Library category font-weight*/
  --category-title-font-weight: 600;
  --category-sub-title-font-weight: 500;


/* form label font size*/
--form-label-font-size:12px;
--form-label-weight:500;
/* --form-label-color:#6f6f6f; */

/* form sub-heading label*/
--form-sub-heading-font:12px;
--form-sub-heading-weight:500;
--form-sub-heading-color:#737994;
--form-sub-line-height:10px ;
}

