.header-top-right[data-customClass="custom-design"] {
    flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    height: 60px;
    padding: 15px;
    align-items: center;
    background: var(--secondary-color);
    margin-right: 18px;
}

.part-two.ub-box-szg_border-box {
    display: flex;
    align-items: center;
}

.search {
    border-radius: 15px;
    width: 300px;
    font-size: 12px;
    font-weight: 600;
    color: black;
}

/* .notification {
    border-radius: 50%;
    border: 1px solid rgb(213, 212, 212);
    padding: 6px;
    box-sizing: content-box;
} */

p.search-first-placeholder {
    margin-right: 133px;
}

.search-icon {
    margin-right: auto;
}

/* .profile-picture[data-customClass="custom-design"]:before {
    content: '';
    width: 1.5px;
    height: 24px;
    position: absolute;
    top: 20px;
    background: #E9E9E9;
    right: 132px;
} */

span.Gabriele-Wilson {
  
    color: black;
}

img.header-text-img {
    margin-bottom: -7px;
    margin-left: 6px;
    width: 25px;
    height: 25px;
}

.user-profile {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    margin-top: 5px;
}

.header-search-bar {
    width: 233px;
    border-radius: 20px;
    font-weight: 600;
}

/* .notification-count {
    padding: 0;
    width: 15px;
    height: 15px;
    background: #a6ced6;
    color: black;
    font-size: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
} */

.user-hello[data-customClass="custom-design"] {
    color: #2f2f2f;
    font-weight: 500;
    font-size: 14px;
}

.user-name[data-customClass="custom-design"] {
    color: #000;
    font-weight: 700;
    font-size: 16px;
}

.log-out-inner-wrapper[data-customClass="custom-design"] {
    width: 160px !important;
    padding-left: 10px !important;
    text-transform: capitalize !important;
    border-bottom: 1px solid #2a2828 !important;
    background-color: black !important;
}

.title-box[data-customClass="custom-design"] {
    font-size: 10px;
    font-weight: 400;
    color: #FFF;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100px;
}

.icons {
    filter: brightness(0) invert(1);
    width: 12px;
}

.account-setting-wrapper {
    display: flex;
    gap: 10px;
}

.main-spinner {
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 99;
    right: 0;
    top: 0;
    height: 100%;
    opacity: 0.8;
}

.avatar-design * {
    background: var(--accent-color) !important;
    color: var(--accent-color-inverted) !important;
    font-size: 14px !important;
    font-weight: 600 !important;
    text-transform: uppercase !important;
}