/* dashboard-main-container-start*/

.dashboard-inner-container {
    height: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

/* end*/



/* dashboard-top-container-css-start*/

.dashboard-top-container {
    height: 100%;
}

.left-side-grid {
    display: grid;
    grid-template-columns: 57% auto;
    height: 100%;
    grid-column-gap: 20px;
    width: 100%
}

.left-side-grid-box {
    width: 100%;
    height: 100%;
}

.left-side-grid-header {
    height: 30px;
}

.left-side-grid-header-text[data-customClass="custom-design"],
.right-side-grid-header-text[data-customClass="custom-design"],
.new-releases-box-header-text[data-customClass="custom-design"],
.trending-box-box-header-text[data-customClass="custom-design"] {
    font-size: var(--heading-title-font-size);
    color: var(--heading-text-color);
    text-transform: uppercase;
    font-weight: var(--font-weight);
}


.left-side-grid-container {
    height: calc(100% - 30px);
    width: 100%;
}

.second-grid-header {
    height: 30px;
}

.second-grid-container {
    display: grid;
    gap: 10px;
    height: calc(100% - 30px);

}

.second-grid-container-first-panel {
    grid-column-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-row-gap: 10px;

}

/* .second-grid-container-second-panel {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10px;

} */

.right-wrapper-img {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;

}

.first-img {
    min-width: 100%;
    min-height: 100%;
    object-fit: fill;
}

.right-wrapper {
    display: grid;
    grid-template-columns: 45% auto;
    background-color: var(--secondary-color);
    border-radius: 10px;
    border: 1px solid #f0eaea;
    grid-column-gap: 10px;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
}

.right-wrapper-data[data-customClass="custom-design"] {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 10px;
}

.right-wrapper-data[data-customClass="custom-design"],
.right-wrapper-title[data-customClass="custom-design"] {
    color: var(--heading-text-color);
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    display: block;
    width: 85px;
}

.right-wrapper-title::first-letter {
    text-transform: capitalize;
}


.right-wrapper-data[data-customClass="custom-design"],
.right-wrapper-sub-title[data-customClass="custom-design"] {
    color: var(--sub-heading-text-color);
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
    margin-top: -4px;
}

.right-wrapper-data[data-customClass="custom-design"],
.right-wrapper-icon-title[data-customClass="custom-design"] {
    color: var(--heading-text-color);
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    display: flex;
    justify-content: center;
}

.icon-text {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: -10px;
    color: var(--accent-color);
}

.right-wrapper-inner-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

}

/*end*/




/* dashboard-bottom-css-start*/

.dashboard-bottom-container {
    height: 100%;
}

.second-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 20px;
    height: 100%;
}

.counting-box {
    height: 100%;
}

.img-list-container {
    display: flex;
    width: 20px;
    height: 20px;
    gap: 2px;
    flex-direction: row-reverse;


}

.img-row {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.counting-box-data-list-inner {
    display: flex;
    justify-content: space-between;
    padding: 15px;
    align-items: center;
    background: white;
    border: 1px solid #f0eaea;
    border-radius: 10px;
    margin-bottom: 8px;
}

.icon {
    background: var(--heading-text-color);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-number {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: 15px;
}

.comunity-Name[data-customClass="custom-design"] {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: var(--sub-heading-text-color);
    text-transform: capitalize;
    margin-bottom: 5px;
}

.coutning {
    font-size: 25px !important;
    font-weight: var(--font-weight) !important;
    color: var(--heading-text-color) !important;
}

.img-counting-number[data-customClass="custom-design"] {
    position: relative;
    left: 2px;
    font-size: 8px;
    color: var(--heading-text-color);
    font-weight: 600;
}

/* dashboard-bottom-css-end*/





/*dashboard-new-realease-css-start*/

.new-releases-box {
    padding: 10px 20px 0 20px;
    border-radius: 10px;
    height: calc(100% - 10px);

}

.new-releases-box-header {
    height: 30px;
}

.new-releases-box-data {
    height: calc(100% - 30px);
}

.release-data-img {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    overflow: hidden;
    border-radius: 8px;

}

.release-img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.new-releases-data-list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 14px;

}

.release-data-title {
    display: flex;
    flex-direction: column;
}

.release-data-series {
    display: flex;
    gap: 5px;
}

.new-container {
    display: flex;
    gap: 15px;
}

.release-heading-title[data-customClass="custom-design"] {
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 135px;
}

.release-sub-heading-title[data-customClass="custom-design"] {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: var(--sub-heading-text-color);
    text-transform: capitalize;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 135px;
}

.release-data-series-count[data-customClass="custom-design"] {
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
}

.release-data-series-title[data-customClass="custom-design"] {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: var(--sub-heading-text-color);
    text-transform: capitalize;
}

/*dashboard-new-realease-css-end*/


/*dashboard-trending-css-start*/


.trending-box {
    height: calc(100% - 10px);
    background-color: var(--secondary-color);
    padding: 10px 0px 0 20px;
    border-radius: 10px;
    border: 1px solid #f0eaea;
}

.trending-box-box-header {
    height: 30px;
}

.trending-box-box-data {
    height: calc(100% - 30px);
}

.trending-box-item-list-wrapper-inner {
    display: flex;
    gap: 15px;
    align-items: center;
    margin-bottom: 14px;
}



.trending-box-item-list-text[data-customClass="custom-design"] {
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 190px;
    display: block;

}

.trending-box-item-list-points {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: var(--sub-heading-text-color);
    text-transform: capitalize;

}

/* dashboard-tranding-css-end*/

#new-released-bg-poster {
    background-origin: border-box;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    height: calc(100% - 10px);
    width: 100%;
}

span.right-wrapper-icon-title {
    font-size: 10px;
}