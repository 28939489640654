/* 

sidebar design css

*/


.sidebar-container {
  height: 100%;
  width: 200px;
  text-align: center;
  background-color: var(--secondary-color);
}

.sidebar-container .Logo .logo-img {
  margin-top: 13px;
  border-radius: 10px;
  width: 100px;
  background-position: center;
  object-fit: cover;
}

.sidebar-container .menu-item-list {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/*
This is button before css  
*/
.css-1twqfud::before,
[data-css-1twqfud]::before,
.css-1twqfud[data-simulate-before],
[data-css-1twqfud][data-simulate-before] {
  background-color: transparent;
}




.ub-mr_8px {
  color: var(--sub-heading-text-color);
}

.css-1twqfud.ub-h_32px.ub-dspl_flex.ub-algn-itms_center.ub-otln_iu2jf4.ub-txt-deco_none.ub-pst_relative.ub-pl_12px.ub-pr_12px.ub-bg-clr_white.ub-box-szg_border-box {
  /* width: 208px; */
  background: transparent;
  padding-left: 0;
  padding-right: 0;
}

.css-12b25td.ub-pst_relative.ub-f-wght_500.ub-dspl_inline-flex.ub-algn-itms_center.ub-flx-wrap_nowrap.ub-just-cnt_center.ub-txt-deco_none.ub-ver-algn_middle.ub-b-btm_1px-solid-c1c4d6.ub-b-lft_1px-solid-c1c4d6.ub-b-rgt_1px-solid-c1c4d6.ub-b-top_1px-solid-c1c4d6.ub-otln_iu2jf4.ub-usr-slct_none.ub-crsr_pointer.ub-wht-spc_nowrap.ub-fnt-fam_b77syt.ub-bblr_4px.ub-bbrr_4px.ub-btlr_4px.ub-btrr_4px.ub-color_474d66.ub-tstn_n1akt6.ub-h_32px.ub-min-w_32px.ub-fnt-sze_12px.ub-ln-ht_32px.ub-pl_16px.ub-pr_16px.ub-bg-clr_white.ub-mb_8px.ub-mt_8px.ub-mr_12px.ub-box-szg_border-box {
  background: transparent;
  border: none;
  color: white;
  padding: 21px;
  margin: 0;
}



.ub-fnt-sze_14px.ub-f-wght_400.ub-ln-ht_20px.ub-ltr-spc_-0-05px.ub-fnt-fam_b77syt.ub-color_474d66.ub-ml_16px.ub-mr_16px.ub-flx_1.ub-box-szg_border-box {
  gap:8px;
  font-size: 12px;
  color: black;
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 13px;
  border-radius: 8px;
}

.css-12b25td.ub-pst_relative.ub-f-wght_500.ub-dspl_inline-flex.ub-algn-itms_center.ub-flx-wrap_nowrap.ub-just-cnt_center.ub-txt-deco_none.ub-ver-algn_middle.ub-b-btm_1px-solid-c1c4d6.ub-b-lft_1px-solid-c1c4d6.ub-b-rgt_1px-solid-c1c4d6.ub-b-top_1px-solid-c1c4d6.ub-otln_iu2jf4.ub-usr-slct_none.ub-crsr_pointer.ub-wht-spc_nowrap.ub-fnt-fam_b77syt.ub-bblr_4px.ub-bbrr_4px.ub-btlr_4px.ub-btrr_4px.ub-color_474d66.ub-tstn_n1akt6.ub-h_24px.ub-min-w_24px.ub-fnt-sze_12px.ub-ln-ht_24px.ub-pl_12px.ub-pr_12px.ub-bg-clr_white.ub-mb_8px.ub-mt_8px.ub-mr_12px.ub-box-szg_border-box {
  background: transparent;
  border: none;
}


.ub-ml_-3px {
  margin-left: 5px;
  color: #878383 !important;
}

.css-12b25td:not([disabled]):focus,
[data-css-12b25td]:not([disabled]):focus,
.css-12b25td[data-simulate-notdisabledfocus],
[data-css-12b25td][data-simulate-notdisabledfocus] {
  box-shadow: none;
}

a {
  color: #878383;
  text-transform: capitalize;
  font-size: 12.5px;
  font-weight: 600;
  text-decoration: none;
  width: 160px;

}

.active {
  text-decoration: none !important;
}

/* .css-1twqfud[aria-current="true"],
[data-css-1twqfud][aria-current="true"],
.css-1twqfud[data-isselectable="true"]:active,
[data-css-1twqfud][data-isselectable="true"]:active {
  background-color: black !important;
  border-radius: 6px;
} */


.css-12b25td.ub-pst_relative.ub-f-wght_500.ub-dspl_inline-flex.ub-algn-itms_center.ub-flx-wrap_nowrap.ub-just-cnt_center.ub-txt-deco_none.ub-ver-algn_middle.ub-b-btm_1px-solid-c1c4d6.ub-b-lft_1px-solid-c1c4d6.ub-b-rgt_1px-solid-c1c4d6.ub-b-top_1px-solid-c1c4d6.ub-otln_iu2jf4.ub-usr-slct_none.ub-crsr_pointer.ub-wht-spc_nowrap.ub-fnt-fam_b77syt.ub-bblr_4px.ub-bbrr_4px.ub-btlr_4px.ub-btrr_4px.ub-color_878383.ub-tstn_n1akt6.ub-h_40px.ub-min-w_40px.ub-fnt-sze_14px.ub-ln-ht_40px.ub-pl_20px.ub-pr_20px.ub-bg-clr_white.ub-mb_8px.ub-mt_8px.ub-mr_12px.ub-box-szg_border-box {
  background: transparent;
  border: none;
}

#sidebar-left nav a {
  margin: 4px;
  padding: 5px;
}

#sidebar-left nav a svg path#speedometer {
  fill: #000;
}

#sidebar-left nav .active {
  background-color: var(--accent-color);
  color: var(--accent-color-inverted);
  text-decoration: none !important;
  width: 160px;
  border-radius: 5px;
}

#sidebar-left nav .active svg path#speedometer {
  fill: var(--accent-color-inverted);
}


#sidebar-left nav .active span {
  color: var(--accent-color-inverted);
  display: flex;
  margin-left: 13px;
  margin-right: auto;
  /* filter: brightness(0) invert(1); */
}

img.sidebar-button-list-icon {
  width: 15px;
  height: auto;
  filter: brightness(0%);
}

img.sidebar-library-icon {
  width: 10px;
  height: auto;
  filter: brightness(0%);
}

.sidebar-library-design {
  gap: 8px;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: auto;
}