.content-inner .left-panel .left-panel-header .left-panel-header-first-line {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.tenant-header-name[data-customClass="custom-design"] {
    color: var(--heading-text-color);
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
}


.tenant-header-title[data-customClass="custom-design"] {
    color: var(--heading-text-color) !important;
    font-size: var(--heading-title-font-size) !important;
    font-weight: var(--heading-font-weight) !important;
    text-transform: uppercase !important;
    overflow: hidden;
    width: 100px;
    text-overflow: ellipsis;
    white-space: nowrap;

}


.tenant-form-header,
.tenant-edit-form-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dcdbdb;
    padding: 10px 10px 10px 20px;
}


.tenant-form-header>.tenant-form-heading,
.tenant-edit-form-heading[data-customClass="custom-design"] {
    font-size: var(--heading-title-font-size);
    font-weight: var(--font-weight);
    color: var(--heading-text-color);
    text-transform: capitalize;
}

.tenant-form-header>.tenant-form-sub-heading,
.tenant-edit-form-sub-heading[data-customClass="custom-design"] {
    font-size: var(--form-sub-heading-font);
    font-weight: var(--form-sub-heading-weight);
    color: var(--form-sub-heading-color);
    line-height: var(--form-sub-line-height);
}




.tenant-form-heading {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
}

.tenant-form-panel {
    padding-left: 20px;
    padding-right: 20px;
}

.edit-tenant-form-panel {
    padding-top: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.tenant-branding-panel {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

.tenant-user-detail-form,
.tenant-detail-form {
    margin-left: -16px;
}

.tenant-form-buttons-panel {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: whitesmoke;
    color: var(--heading-text-color);
    text-align: end;
}

.tenant-list-panel {
    display: flex;
    background-color: var(--secondary-color);
    margin-bottom: 10px;
    border-radius: 10px;
    padding: 10px;
    align-items: center;
    margin-top: 7px;
    border: 2px solid transparent;
}


.tenant-list-panel.active {
    border: 2px solid var(--accent-color);
}

.tenant-list-panel:focus {
    border: 2px solid var(--accent-color);
}

.tenant-list-panel .tenant-user-img {
    display: inline-block;
    position: relative;
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 10px;

}

.is-active {
    background-color: var(--heading-text-color);
}

.left-panel-content .tenant-list-panel .tenant-user-img .tenant-img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.tenant-user-inner-container {
    margin-left: 11px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 146px;
    text-transform: capitalize;
}

.tenant-user-inner-container[data-customClass="custom-design"] {
    color: var(--heading-text-color);
    font-size: var(--sub-heading);
    font-weight: var(--font-weight);
    margin-top: 4px;
}

.tenant-user-name[data-customClass="custom-design"] {
    color: var(--heading-text-color);
    font-size: var(--sub-heading);
    font-weight: var(--font-weight);
    margin-top: 4px;
}


.date-time {
    display: flex;
    align-items: center;
    align-content: center;
    gap: 15px;
    font-size: var(--sub-inner-heading);
    text-transform: capitalize;
}

.left-panel-content .left-panel-content-moreicon {
    transform: rotate(89deg);
    position: relative;
    color: #b5b0b0;
    left: 270px;
    bottom: 77px;
}

.middle-panel .tenant-header-panel {
    width: 100%;
    height: 80px;
}

.middle-panner-wrapper {
    padding: 20px;
}

.middle-panel .tenant-content-panel {
    width: 100%;
    height: calc(100% - 80px);
    border-radius: 10px;
}

.tenant-desc {
    line-height: 14px;
}

.tenant-header-list-panel {
    gap: 20px;
    display: flex;
    border-radius: 10px;
    align-items: center;
}

.tenant-header-img {
    display: inline-block;
    position: relative;
    width: 75px;
    height: 75px;
    overflow: hidden;
    border-radius: 10px;
}

.header-img {
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
}

.tenant-header-heading {
    text-transform: capitalize;
    margin-right: auto;
    overflow: hidden;
    width: 200px;
    text-overflow: ellipsis;
    white-space: nowrap;
}


.middle-panel-header-moreicon {
    transform: rotate(89deg);
    margin-right: 10px;
    cursor: pointer;
}

.tenant-content-description[data-customClass="custom-design"] {
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    color: #606060;
    opacity: 0.9;
}

.tenant-content-description-list {
    display: grid;
    margin-top: 20px;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: initial;
}

.tenant-content-description-list-one {
    color: var(--sub-heading-text-color);
    font-size: var(--sub-inner-heading);
    font-weight: var(--sub-inner-heading-font-weight);
    text-transform: capitalize;
}

.tenant-content-description-list-two {
    color: var(--heading-text-color);
    font-size: var(--sub-heading);
    font-weight: var(--sub-heading-font-weight);
    text-transform: capitalize;
}

.primary {
    background: #271C6B;
    color: #271C6B;
    border-radius: 3px;
}

.secondary {
    background: #0B91D4;
    color: #0B91D4;
    border-radius: 3px;
    margin-left: 5px;
}

.tenant-content-count-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 13px;
    margin-top: 20px;
}

.tenant-user-count {
    display: flex;
    gap: 10px;
    border: 1px solid #eae7e7;
    width: 100%;
    border-radius: 8px;
    height: 60px;
}

.tenant-podcast-count {
    display: flex;
    gap: 10px;
    border: 1px solid #eae7e7;
    width: 100%;
    border-radius: 8px;
    height: 60px;
}

.tenant-user-count-icon,
.tenant-podcast-count-icon {
    background: var(--heading-text-color);
    width: 26px;
    height: 26px;
    border-radius: 50%;
    color: white;
    padding: 1px 0px 8px 7px;
    margin: 16px 0 16px 16px;
}

.tenant-list-more-icon {
    transform: rotate(89deg);
    position: absolute;
    right: 10px;
    margin-top: -7px;
    cursor: pointer;
}

.create-tenant-button[data-customClass="custom-design"]:hover {
    border: none !important;
    background-color: #A6CED6 !important;
}

.ub-ovflw-y_auto {
    overflow: hidden;
}

.tenant-search-button {
    width: 100%;
    margin-top: 20px;
    background: #F1F1F1 0% 0% no-repeat padding-box;
    border: 1px solid #E9E9E9;
    font-weight: 700;
    color: var(--heading-text-color);
    font-size: 13px;
    border-radius: 8px;
}

.tenant-searchicon-two {
    margin-left: -7px;
    margin-right: 12px;
}


.tenantTablist [aria-selected="true"],
.tenantTablist:focus
.tenantTablist:active {
  background: transparent !important;
  color: var(--accent-color) !important;
  border-bottom: 1.5px solid var(--accent-color) !important;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  box-shadow: unset;
}

#ttab1,
#ttab2 {
    font-size: 10px;
    background: transparent !important;
    color: rgb(139, 139, 139);
    font-size: var(--sub-inner-heading);
    margin-right: 13px;
    padding-left: 0;
    border-bottom: 1.5px solid transparent;
}

.brandingBox {
    width: 18px;
    height: 18px;
    border-radius: 3px;
    margin: 2px;
    display: inline-block;
    border: 1px solid black;
}

.btn-continue {
    float: right;
    position: relative;
    bottom: 70px;
}

.disabled-button[data-customClass="custom-design"] {
    float: right;
    outline: none;
    align-items: center !important;
    border: 1px solid var(--accent-color);
    border-radius: 40px !important;
    font-size: var(--create-button) !important;
    font-weight: var(--font-weight) !important;
    text-transform: capitalize !important;
    color: var(--accent-color);
}

.back-button-tenant[data-customClass="custom-design"] {
    align-items: center !important;
    border: 2px solid var(--accent-color);
    border-radius: 40px !important;
    font-size: var(--create-button) !important;
    font-weight: var(--font-weight) !important;
    text-transform: capitalize !important;
    color: var(--accent-color);

}

.disabled-button[data-customClass="custom-design"],
.back-button-tenant[data-customClass="custom-design"]:hover {
    border: 2px solid var(--accent-color);

}

/* disabled button font color*/
/* .css-12b25td[disabled],
[data-css-12b25td][disabled] {
    color: #878787 !important;
} */

.brandingInput {
    padding: 0;
    width: 30px;
    height: 30px;
    border: unset;
}

.tenant-form-container-inner-wrapper {
    border-bottom: 1px solid #dedada;
}


.defaultImage {
    height: 40px;
    border-radius: 10px;
}

.defaultImagePara {
    font-size: 10px;
    margin-top: -8px;
}

#import-user-panel {
    padding-top: 10px;
}

form.user-form-panel .ub-mb_0px {
    font-size: 12px;
}